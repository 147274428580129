.account-table {
  .features {
    color: gray;
    
    .enabled {
      color: green;
    }
  }

  textarea {
    width: 100%;
    field-sizing: content;
  }
}